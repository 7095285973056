




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';

export default Vue.extend({
  name: 'product-list',
  components: {
    ...vuetifyComponents,
  },
  props: {
    loading: Boolean,
    products: Array,
  },
});
