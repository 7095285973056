




import Vue from 'vue';
import xBlob from '@/services/xBlob';
import { mapState } from 'vuex';
import RabbitButton from '@/components/buttons/RabbitButton.vue';
import XrImg from '@/components/image/XrImg.vue';
import { vuetifyComponents } from '@/plugins/vuetify';

export default Vue.extend({
  name: 'part-banner',
  components: {
    RabbitButton,
    ...vuetifyComponents,
    XrImg,
  },
  computed: {
    ...mapState({
      part: (state: any) => state.part.part,
    }),
  },
  data: () => ({
    downloading: false,
    errorMessage: '',
  }),
  methods: {
    downloadPart: function() {
      this.downloading = true;
      xBlob
        .get(this.part.blendDownloadUrl)
        .then(result => {
          this.downloading = false;
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.part.blendName + '.blend');
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          this.downloading = false;
          this.errorMessage = 'Unable to Download';
        });
    },
  },
  props: {
    loading: Boolean,
  },
});
